$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/carreisen-ostschweiz/';
@import 'styles/helpers/global_variables';

.root {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  padding: 0.4rem 0;
  z-index: 9998;
  @include drop-shadow-1;
}
